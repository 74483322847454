import { FlexMixin, FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import { BorderRadius } from '@babylon/ui-kit-styles/common/mixins/logical.styled';
import styled from 'styled-components';

import * as SS from '../styled';

export const NewsletterSubscriptionStatusContent = styled(SS.NewsletterSubscriptionContent)`
	position: absolute;
	top: 16px;
	left: 0;
	width: 100%;
	height: calc(100% - 32px);
	${FlexMixin({ align: 'center', justify: 'center' })}
	z-index: ${({ theme }) => theme.zIndex.low};
	text-align: center;

	${({ theme }) => theme.media.up('lg')} {
		width: calc(100% - 40px);
	}
`;

export const NewsletterSubscriptionStatusContainer = styled.div`
	display: block;

	& .title {
		${FontMixin({ size: 'large', weight: 'medium' })}
		margin-bottom: 8px;
	}

	& .description {
		${FontMixin({ size: 'medium' })}
	}

	& i {
		position: absolute;
		right: 0;
		top: 0;
		cursor: pointer;
		color: ${({ theme }) => theme.colors.white.base};
		padding: 12px;
	}

	& .loading {
		${FlexMixin({ justify: 'space-between', align: 'center' })}
		& .text {
			padding-left: 15px;
		}
		& .spinner {
			animation: spinner 1s linear infinite;
			border: 2px solid ${({ theme }) => theme.colors.white.base};
			border-left-color: transparent;
			${BorderRadius({ all: '50%' })}
			content: '';
			display: inline-block;
			height: 32px;
			width: 32px;
			z-index: ${({ theme }) => theme.zIndex.lower};
		}
	}

	@keyframes spinner {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;
