import { FlexMixin, FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import styled from 'styled-components';

export const BannerButtonWidthWrapperMobile = styled.div<{ backgroundImage?: string }>`
	background-image: url(${({ backgroundImage }) => backgroundImage});
	background-repeat: no-repeat;
	background-size: cover;
	max-height: 500px;
	padding: 32px 0;
`;

export const BannerButtonWidthLinkMobile = styled.a`
	${FlexMixin({ align: 'center', direction: 'column', justify: 'center' })}
	padding: 0 16px;
	margin: 0 auto;
	width: 100%;
`;

export const BannerButtonWidthLogoMobile = styled.div`
	margin: 0;
	width: auto;
`;

export const BannerButtonWidthInfoMobile = styled.div`
	padding: 0 16px;
	text-align: center;
	width: auto;

	& > p {
		${FontMixin({ size: 'base', weight: 'light', height: 'base' })}
		color: ${({ theme }) => theme.colors.grays.darker};
		margin-bottom: 24px;
	}
`;
