import { DEVICES } from '@babylon/ui-kit-context/types';
import type { BaseTemplateLogitravelProps } from '@babylon/ui-kit-layout/components/logitravel/base-template/types';

import type { MainHomeDesktopProps, MainHomeMobileProps } from '@/pages/home';

import type { AppProps } from '.';

export const transformProps = ({ root, context }: AppProps) => {
	const isMobile = context.DeviceClass === DEVICES.mobile;

	if (isMobile) {
		const typedRoot = root as MainHomeMobileProps;

		const templateProps: BaseTemplateLogitravelProps = {
			context,
			mHeader: typedRoot.props.header,
			mFooter: typedRoot.props.footer,
		};

		return { templateProps, mainHomeProps: typedRoot };
	}

	const typedRoot = root as MainHomeDesktopProps;
	const { header, footer } = typedRoot.props;
	const templateProps: BaseTemplateLogitravelProps = { context, header, footer };

	return { templateProps, mainHomeProps: typedRoot };
};
