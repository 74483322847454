import InnerMarkdownHTML from '@babylon/ui-kit-base/components/text/inner-markdown-HTML';
import Title from '@babylon/ui-kit-base/components/text/title';
import type { FC } from 'react';
import { Children } from 'react';

import * as S from './styled';
import type { TextDescriptionsProps } from './types';

const TextDescriptions: FC<TextDescriptionsProps> = ({ title, descriptions = [] }) => (
	<S.CardDescriptionsWrapper data-testid='TextDescriptions'>
		{title && <Title title={title} />}
		{Children.toArray(
			descriptions.map(item => (
				<S.ListCardDescriptions>
					{item.highlight && <S.IconWrapper className='nico-check' />}
					<InnerMarkdownHTML tag='p' content={item.content} />
				</S.ListCardDescriptions>
			))
		)}
	</S.CardDescriptionsWrapper>
);

export default TextDescriptions;
