export const parseRootProps = <CM extends {}, P extends {} = {}>(
	props: P,
	componentsNames: Record<string, string>
): CM => {
	const componentsMap: Partial<CM> = {};

	Object.entries<any>(props).forEach(([propsKey, propsValue]) => {
		if (!propsValue) return;

		const { name } = propsValue;

		const key = componentsNames[name];
		if (key) componentsMap[key as keyof CM] = propsValue;
		else if (typeof propsValue === 'object') componentsMap[propsKey as keyof CM] = propsValue;
	});

	return componentsMap as CM;
};
