import { FlexMixin, FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import styled from 'styled-components';

export const BannerButtonWidthWrapper = styled.div<{ backgroundImage?: string }>`
	background-image: url(${({ backgroundImage }) => backgroundImage});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	max-height: 500px;
	padding: 32px 0;
`;

export const BannerButtonWidthLink = styled.a`
	${FlexMixin({ align: 'center', justify: 'center' })}
	margin: 0 auto;
	max-width: 1500px;
	padding: 0 20px;
	position: relative;
	width: 100%;

	@media (max-width: ${props => props.theme.breakpoints.lg}px) {
		${FlexMixin({ direction: 'column' })}
	}
`;

export const BannerButtonWidthLogo = styled.div`
	flex: 2;
	margin-right: 60px;
`;

export const BannerButtonWidthInfo = styled.div`
	flex: 3;
	padding-right: 24px;

	@media (max-width: ${props => props.theme.breakpoints.lg}px) {
		padding: 0 16px;
		text-align: center;
		width: auto;
	}

	& > p {
		${FontMixin({ size: 'base', weight: 'light', height: 'base' })}
		color: ${({ theme }) => theme.colors.grays.darker};
		margin-bottom: 24px;
	}
`;
