import Icon from '@babylon/ui-kit-base/components/icons/icon';
import { FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import styled from 'styled-components';

export const CardDescriptionsWrapper = styled.div`
	background-color: ${({ theme }) => theme.bodyBgColor};
	border-bottom: 1px solid ${({ theme }) => theme.colors.grays.light};
	margin-bottom: 20px;
	padding: 20px 16px 24px;
	position: relative;

	&:before {
		background-color: ${({ theme }) => theme.colors.white.base};
		border-left: 1px solid ${({ theme }) => theme.colors.grays.light};
		border-top: 1px solid ${({ theme }) => theme.colors.grays.light};
		bottom: -20px;
		content: '';
		height: 20px;
		left: calc(50% - 20px);
		position: absolute;
		transform: translate(50%, -50%) rotate(45deg);
		width: 20px;
	}
`;

export const ListCardDescriptions = styled.div`
	${FontMixin({ size: 'medium', weight: 'light', height: 'medium' })}
	color: ${({ theme }) => theme.colors.grays.darker};
	display: flex;
	margin-bottom: 8px;
`;

export const IconWrapper = styled(Icon)`
	${FontMixin({ size: 'base', weight: 'bold' })}
	color: ${({ theme }) => theme.colors.status.success};
	display: inline-block;
	margin-right: 10px;
`;
