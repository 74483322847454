import CarouselHeader from '@babylon/ui-kit-carousels/components/carousel-header';
import Comarketing from '@babylon/ui-kit-common/components/others/comarketing';
import type { ComarketingProps } from '@babylon/ui-kit-common/components/others/comarketing/types';
import LastNews from '@babylon/ui-kit-common/components/others/last-news';
import type { LastNewsProps } from '@babylon/ui-kit-common/components/others/last-news/types';
import ProductsBanner from '@babylon/ui-kit-common/components/others/products-banner';
import type { ProductsBannerProps } from '@babylon/ui-kit-common/components/others/products-banner/types';
import ScrollToTop from '@babylon/ui-kit-common/components/others/scroll-to-top';
import withHydrationOnDemand from '@babylon/ui-kit-hocs/hydration-on-demand/withHydrationOnDemand';
import { SectionSearcher } from '@babylon/ui-kit-styles/common/mixins/section.styled';
import BlogMarketing from '@babylon/ui-kit-wrappers/components/marketing/blog';
import { FeaturesMarketing } from '@babylon/ui-kit-wrappers/components/marketing/features';
import MasonryCarouselMarketing from '@babylon/ui-kit-wrappers/components/marketing/masonry';
import TabsCarouselProductCardMarketing from '@babylon/ui-kit-wrappers/components/marketing/tabs-carousel-product-card';
import Multisearcher from '@babylon/ui-kit-wrappers/components/others/multisearcher';
import type { MultisearcherProps } from '@babylon/ui-kit-wrappers/components/others/multisearcher/types';
import MapCoasts from '@babylon/ui-kit-wrappers/components/transports/map-coasts';
import type {
	MapCoastsMobileProps,
	MapCoastsProps,
} from '@babylon/ui-kit-wrappers/components/transports/map-coasts/types';
import type { BlogMarketingDesktopProps } from '@babylon/ui-kit-wrappers/helpers/marketing/blog/desktop/types';
import type { FeaturesMarketingProps } from '@babylon/ui-kit-wrappers/helpers/marketing/features/types';
import type { MasonryCarouselMarketingDesktopProps } from '@babylon/ui-kit-wrappers/helpers/marketing/masonry-carousel/desktop/types';
import type { TabsProductCardMarketingProps } from '@babylon/ui-kit-wrappers/helpers/marketing/tabs-product-card/types';
import type { ComponentProps, FC } from 'react';

import { BannerButtonWidth } from '@/components/banner-button-width';
import type { BannerButtonWidthProps } from '@/components/banner-button-width/types';
import type { CollapseLinksProductsProps } from '@/components/collapse-links-products';
import { CollapseLinksProducts } from '@/components/collapse-links-products';
import { ListCardSimpleHover, type ListCardSimpleHoverProps } from '@/components/list-card-simple-hover';
import type { NewsletterSubscriptionProps } from '@/components/newsletter-subscription';
import NewsletterSubscription from '@/components/newsletter-subscription';
import { SectionImageLink } from '@/components/section-image-link';
import type { SectionImageLinkProps } from '@/components/section-image-link/types';
import type { TrustedShopsProps } from '@/components/trusted-shops';
import TrustedShops from '@/components/trusted-shops';
import { SectionBgSecondary, SectionWithBorder } from '@/helpers/SectionWithBorder';

import { parseRootProps } from '../../../../utils/render/parse';
import * as S from './styled';
import type { ComponentDesktopMap, MainHomeDesktopProps } from './types';

const CarouselHeaderHydration = withHydrationOnDemand<ComponentProps<typeof CarouselHeader>>()(CarouselHeader);
const ListCardSimpleHoverHydration = withHydrationOnDemand<ListCardSimpleHoverProps>()(ListCardSimpleHover);
const MasonryCarouselHydration =
	withHydrationOnDemand<MasonryCarouselMarketingDesktopProps>()(MasonryCarouselMarketing);
const NewsletterSubscriptionHydration = withHydrationOnDemand<NewsletterSubscriptionProps>()(NewsletterSubscription);
const ProductsBannerHydration = withHydrationOnDemand<ProductsBannerProps>()(ProductsBanner);
const FeaturesHydration = withHydrationOnDemand<FeaturesMarketingProps>()(FeaturesMarketing);
const CollapseLinksProductsHydration = withHydrationOnDemand<CollapseLinksProductsProps>()(CollapseLinksProducts);
const SectionImageLinkHydration = withHydrationOnDemand<SectionImageLinkProps>()(SectionImageLink);
const TrustedShopsHydration = withHydrationOnDemand<TrustedShopsProps>()(TrustedShops);
const ComarketingHydration = withHydrationOnDemand<ComarketingProps>()(Comarketing);
const TabsProductCardHydration = withHydrationOnDemand<TabsProductCardMarketingProps>()(
	TabsCarouselProductCardMarketing
);
const BlogHydration = withHydrationOnDemand<BlogMarketingDesktopProps>()(BlogMarketing);
const BannerButtonWidthHydration = withHydrationOnDemand<BannerButtonWidthProps>()(BannerButtonWidth);
const LastNewsWidthHydration = withHydrationOnDemand<LastNewsProps>()(LastNews);
const MultisearcherHydration = withHydrationOnDemand<MultisearcherProps>()(Multisearcher);
const MapCoastsHydration = withHydrationOnDemand<MapCoastsProps | MapCoastsMobileProps>()(MapCoasts);

export const MainHomeDesktop: FC<MainHomeDesktopProps> = ({ props }) => {
	const {
		blog,
		collapseLinksProducts,
		comarketing,
		features,
		headerCarousel,
		listCardSimpleHover,
		masonryCarousel,
		newsletterSubscription,
		productsBanner,
		sectionImageLink,
		tabsProductCard,
		trustedShops,
		bannerButtonWidth,
		lastNews,
		multisearcher,
		mapCoasts,
	} = parseRootProps<ComponentDesktopMap>(props, {
		'blog-feed': 'blog',
		blog: 'blog',
		'collapse-links-products': 'collapseLinksProducts',
		comarketing: 'comarketing',
		features: 'features',
		'header-carousel-rxjs': 'headerCarousel',
		'list-card-simple-hover': 'listCardSimpleHover',
		'masonry-carousel': 'masonryCarousel',
		'newsletter-subscription': 'newsletterSubscription',
		'products-banner': 'productsBanner',
		'section-image-link': 'sectionImageLink',
		'tabs-banner-product-card-carousel': 'tabsProductCard',
		multisearcher: 'multisearcher',
		'alex-trustedshops': 'trustedShops',
		'banner-button-width': 'bannerButtonWidth',
		'last-news': 'lastNews',
		'map-coasts': 'mapCoasts',
	});

	return (
		<S.MainContainer data-testid='MainHome'>
			{headerCarousel?.props && <CarouselHeaderHydration {...headerCarousel} />}

			{multisearcher?.props && (
				<SectionSearcher>
					<MultisearcherHydration {...multisearcher} />
				</SectionSearcher>
			)}

			{tabsProductCard?.props && (
				<S.SectionHome>
					<TabsProductCardHydration {...tabsProductCard} />
				</S.SectionHome>
			)}

			{listCardSimpleHover?.props && (
				<SectionWithBorder>
					<ListCardSimpleHoverHydration {...listCardSimpleHover} />
				</SectionWithBorder>
			)}
			{mapCoasts?.props && (
				<S.MapCoast>
					<MapCoastsHydration {...mapCoasts} />
				</S.MapCoast>
			)}
			{masonryCarousel?.props && (
				<S.SectionHome>
					<MasonryCarouselHydration {...masonryCarousel} />
				</S.SectionHome>
			)}

			{productsBanner?.props && (
				<S.SectionHome>
					<ProductsBannerHydration {...productsBanner} />
				</S.SectionHome>
			)}
			{newsletterSubscription?.props && (
				<SectionBgSecondary>
					<NewsletterSubscriptionHydration {...newsletterSubscription} />
				</SectionBgSecondary>
			)}
			{features?.props && (
				<S.SectionHome>
					<FeaturesHydration {...features} />
				</S.SectionHome>
			)}
			{collapseLinksProducts && (
				<S.SectionHome>
					<CollapseLinksProductsHydration {...collapseLinksProducts} />
				</S.SectionHome>
			)}
			{sectionImageLink?.props && (
				<SectionBgSecondary>
					<SectionImageLinkHydration {...sectionImageLink} />
				</SectionBgSecondary>
			)}
			{comarketing?.props && (
				<S.SectionHome>
					<ComarketingHydration {...comarketing} />
				</S.SectionHome>
			)}
			{trustedShops?.props && (
				<S.SectionHome>
					<TrustedShopsHydration {...trustedShops} />
				</S.SectionHome>
			)}
			{blog?.props && (
				<S.SectionHome>
					<BlogHydration {...blog} />
				</S.SectionHome>
			)}
			{bannerButtonWidth?.props && (
				<S.SectionHome>
					<BannerButtonWidthHydration {...bannerButtonWidth} />
				</S.SectionHome>
			)}

			{lastNews?.props && (
				<S.SectionHome>
					<LastNewsWidthHydration {...lastNews} />
				</S.SectionHome>
			)}

			<ScrollToTop />
		</S.MainContainer>
	);
};

export default MainHomeDesktop;
