export interface BodyPostNewsletterSubscribe {
	email: string;
	customCommunication: boolean;
	origenSuscrip: string;
}

export const fetchPostNewsletterSubscribe = async (
	endpoint: string,
	body: BodyPostNewsletterSubscribe
): Promise<void> => {
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body),
	};

	const response = await fetch(endpoint, requestOptions);
	if (!response.ok || (response.status !== 200 && response.status !== 204)) throw new Error(response.statusText);

	return response.json();
};
