import Title from '@babylon/ui-kit-base/components/text/title';
import type { FC } from 'react';
import { Children } from 'react';

import CardSimpleHover from './card-simple-hover';
import * as S from './styled';
import TextDescriptions from './text-descriptions';
import type { ListCardSimpleHoverMobileProps } from './types';

export const ListCardSimpleHoverMobile: FC<ListCardSimpleHoverMobileProps> = ({ props, ...rest }) => {
	const { title = '', text, items = [] } = props;

	return (
		<div data-testid='ListCardSimpleHoverMobile' {...rest}>
			{text && <TextDescriptions {...text} />}
			<Title title={title} />
			<S.ListCardWrapper>
				{Children.toArray(items.slice(0, 5).map((item, index) => <CardSimpleHover {...item} index={index} />))}
			</S.ListCardWrapper>
		</div>
	);
};

export * from './types';
export default ListCardSimpleHoverMobile;
