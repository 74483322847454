import type { ResizeProps } from '@babylon/ui-kit-base/components/multimedia/image/types';
import { getResizeUrl, hasDomain } from '@babylon/ui-kit-base/components/multimedia/image/utils';
import contextBabylon from '@babylon/ui-kit-context';
import { useContext } from 'react';

type ResizeImage = (src?: string, resize?: ResizeProps) => string;

const useResizeImage: ResizeImage = (src, resize) => {
	const context = useContext(contextBabylon);
	const mscdn = context.Cdn.startsWith('http') ? context.Cdn.substring(6) : context.Cdn;

	if (!src) return '';
	if (resize) return getResizeUrl(resize, mscdn, src);
	return hasDomain(src, mscdn);
};

export default useResizeImage;
