import Title from '@babylon/ui-kit-base/components/text/title';
import { Container } from '@babylon/ui-kit-styles/common/mixins/container.styled';
import type { FC } from 'react';

import { CollapseLinksProductsList } from '../../render-links';
import * as S from './styled';
import type { CollapseLinksProductsMobileProps } from './types';

export const CollapseLinksProductsMobile: FC<CollapseLinksProductsMobileProps> = ({
	title,
	priceFrom,
	list = [],
	...rest
}) => (
	<Container data-testid='CollapseLinksProductsMobile' {...rest}>
		{title && <Title title={title} />}
		{priceFrom && (
			<S.WrapperMobile>
				<CollapseLinksProductsList list={list} priceFrom={priceFrom} iconOpen='plus' iconClose='minus' />
			</S.WrapperMobile>
		)}
	</Container>
);

export * from './types';
export default CollapseLinksProductsMobile;
