import Image from '@babylon/ui-kit-base/components/multimedia/image';
import InnerMarkdownHTML from '@babylon/ui-kit-base/components/text/inner-markdown-HTML';
import { nullsToUndefined } from '@babylon/ui-kit-helpers/null';
import type { FC } from 'react';

import * as S from './styled';
import type { CardSimpleHoverProps } from './types';

const IMAGE_HEIGH: number[] = [260, 261, 165, 185, 168];

const CardSimpleHover: FC<CardSimpleHoverProps> = ({ title, image, link, index }) => {
	const resize = { width: 185, height: IMAGE_HEIGH[index] || 0 };

	return (
		<S.CardSimpleHoverWrapper data-testid='CardSimpleHover'>
			<Image src={image} resize={resize} />
			<S.CardSimpleHoverDescription>
				<InnerMarkdownHTML className='card-simple-hover--title' tag='h3' content={title} />
				{link.content && <span className='card-simple-hover--btn'>{link.content}</span>}
				<a className='card-simple-hover--link' {...nullsToUndefined(link)} />
			</S.CardSimpleHoverDescription>
		</S.CardSimpleHoverWrapper>
	);
};

export default CardSimpleHover;
