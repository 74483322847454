import { Button } from '@babylon/ui-kit-base/components/buttons/button';
import Title from '@babylon/ui-kit-base/components/text/title';
import { FormInput } from '@babylon/ui-kit-forms/components/inputs/input';
import { FormSelect } from '@babylon/ui-kit-forms/components/inputs/select';
import Form from '@babylon/ui-kit-forms/components/others/form';
import { email as emailValidation, required } from '@babylon/ui-kit-forms/helpers/validate';
import type { ISelectOption } from '@babylon/ui-kit-types/select';
import { IStatus } from '@babylon/ui-kit-types/status';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import type { FieldValues } from 'react-hook-form';

import NewsletterSubscriptionStatus from './newsletter-subscription-status';
import type { BodyPostNewsletterSubscribe } from './services';
import { fetchPostNewsletterSubscribe } from './services';
import * as S from './styled';
import type { NewsletterSubscriptionProps } from './types';

export const NewsletterSubscription: FC<NewsletterSubscriptionProps> = ({ props }) => {
	const {
		title = '',
		subtitle,
		email,
		origins = [],
		conditions,
		infoDataProtection = [],
		dataProtection,
		url = '',
		submit,
		loading,
		success,
		error,
	} = props;

	const [isContentVisible, setIsContentVisible] = useState<boolean>(true);
	const [subscriptionStatus, setSubscriptionStatus] = useState<IStatus>(IStatus.DEFAULT);

	const handleContentVisibility = useCallback(() => {
		setSubscriptionStatus(IStatus.DEFAULT);
		setIsContentVisible(true);
	}, []);

	const onSubmitNewsletter = useCallback(
		(values: FieldValues) => {
			const postBody: BodyPostNewsletterSubscribe = {
				email: values.newsletterSubscriptionEmail,
				origenSuscrip: values.newsletterSubscriptionOrigin.value,
				customCommunication: !!values.newsletterSubscriptionConditions,
			};

			setSubscriptionStatus(IStatus.LOADING);
			fetchPostNewsletterSubscribe(url, postBody)
				.then(() => setSubscriptionStatus(IStatus.SUCCESS))
				.catch(() => setSubscriptionStatus(IStatus.ERROR))
				.finally(() => setIsContentVisible(false));
		},
		[url]
	);

	const renderNewsletterSubscriptionStatus = () => {
		switch (subscriptionStatus) {
			case IStatus.LOADING:
				return <NewsletterSubscriptionStatus {...loading} isLoading />;
			case IStatus.SUCCESS:
				return <NewsletterSubscriptionStatus {...success} onClose={handleContentVisibility} />;
			case IStatus.ERROR:
				return <NewsletterSubscriptionStatus {...error} onClose={handleContentVisibility} />;
			default:
				return null;
		}
	};

	const originOptions: ISelectOption[] = useMemo(() => origins.map(x => ({ ...x, label: x.content })), [origins]);

	return (
		<S.NewsletterSubscription data-testid='NewsletterSubscription'>
			{renderNewsletterSubscriptionStatus()}
			<S.NewsletterSubscriptionContent visible={isContentVisible}>
				<Title title={title} subTitle={subtitle} />
				<Form onSubmit={onSubmitNewsletter}>
					<S.NewsletterSubscriptionContentForm>
						<S.ContentFormEmail>
							<FormInput
								placeholder={email.placeholder}
								validate={[emailValidation(), required()]}
								type='email'
								name='newsletterSubscriptionEmail'
							/>
						</S.ContentFormEmail>
						<S.ContentFormOrigin>
							<FormSelect
								name='newsletterSubscriptionOrigin'
								validate={[required()]}
								value={originOptions[0]}
								options={originOptions}
							/>
						</S.ContentFormOrigin>
						<Button type='submit' variant='secondary' size='small'>
							{submit}
						</Button>

						<S.ContentFormConditions
							label={conditions}
							name='newsletterSubscriptionConditions'
							checked={false}
						/>
						<S.NewsletterSubscriptionInfoDataProtection
							dataProtection={{ content: dataProtection }}
							infoDataProtection={{ items: infoDataProtection }}
							placement='bottom'
						/>
					</S.NewsletterSubscriptionContentForm>
				</Form>
			</S.NewsletterSubscriptionContent>
		</S.NewsletterSubscription>
	);
};

export * from './types';
export default NewsletterSubscription;
