import type { MobileBannersProps } from '@babylon/ui-kit-banners/components/banners/types';
import MobileBanners from '@babylon/ui-kit-banners/components/banners/views/mobile';
import LastNews from '@babylon/ui-kit-common/components/others/last-news';
import type { LastNewsProps } from '@babylon/ui-kit-common/components/others/last-news/types';
import MasonryMobile from '@babylon/ui-kit-common/components/others/masonry/views/mobile';
import type { MasonryMobileProps } from '@babylon/ui-kit-common/components/others/masonry/views/mobile/types';
import ProductsBanner from '@babylon/ui-kit-common/components/others/products-banner';
import type { ProductsBannerProps } from '@babylon/ui-kit-common/components/others/products-banner/types';
import withHydrationOnDemand from '@babylon/ui-kit-hocs/hydration-on-demand/withHydrationOnDemand';
import BlogMarketing from '@babylon/ui-kit-wrappers/components/marketing/blog';
import { FeaturesMarketing } from '@babylon/ui-kit-wrappers/components/marketing/features';
import TabsCarouselProductCardMarketing from '@babylon/ui-kit-wrappers/components/marketing/tabs-carousel-product-card';
import Multisearcher from '@babylon/ui-kit-wrappers/components/others/multisearcher';
import type { MultisearcherProps } from '@babylon/ui-kit-wrappers/components/others/multisearcher/types';
import type { MapCoastsMobileProps } from '@babylon/ui-kit-wrappers/components/transports/map-coasts/types';
import MobileMapCoasts from '@babylon/ui-kit-wrappers/components/transports/map-coasts/views/mobile';
import type { BlogMarketingMobileProps } from '@babylon/ui-kit-wrappers/helpers/marketing/blog/mobile/types';
import type { FeaturesMarketingProps } from '@babylon/ui-kit-wrappers/helpers/marketing/features/types';
import type { TabsProductCardMarketingProps } from '@babylon/ui-kit-wrappers/helpers/marketing/tabs-product-card/types';
import type { FC } from 'react';

import { BannerButtonWidth } from '@/components/banner-button-width';
import type { BannerButtonWidthProps } from '@/components/banner-button-width/types';
import { CollapseLinksProducts } from '@/components/collapse-links-products';
import type { CollapseLinksProductsProps } from '@/components/collapse-links-products/types';
import { ListCardSimpleHover } from '@/components/list-card-simple-hover';
import type { ListCardSimpleHoverProps } from '@/components/list-card-simple-hover/types';
import { SectionImageLink } from '@/components/section-image-link';
import type { SectionImageLinkProps } from '@/components/section-image-link/types';
import { TrustedShops } from '@/components/trusted-shops';
import type { TrustedShopsProps } from '@/components/trusted-shops/types';

import { parseRootProps } from '../../../../utils/render/parse';
import * as S from './styled';
import type { ComponentMobileMap, MainHomeMobileProps } from './types';

const ListCardSimpleHoverHydration = withHydrationOnDemand<ListCardSimpleHoverProps>()(ListCardSimpleHover);
const MasonryCarouselHydration = withHydrationOnDemand<MasonryMobileProps>()(MasonryMobile);
const ProductsBannerHydration = withHydrationOnDemand<ProductsBannerProps>()(ProductsBanner);
const FeaturesHydration = withHydrationOnDemand<FeaturesMarketingProps>()(FeaturesMarketing);
const SectionImageLinkHydration = withHydrationOnDemand<SectionImageLinkProps>()(SectionImageLink);
const CollapseLinksProductsHydration = withHydrationOnDemand<CollapseLinksProductsProps>()(CollapseLinksProducts);
const BannersHydration = withHydrationOnDemand<MobileBannersProps>()(MobileBanners);
const TabsProductCardHydration = withHydrationOnDemand<TabsProductCardMarketingProps>()(
	TabsCarouselProductCardMarketing
);
const TrustedShopsHydration = withHydrationOnDemand<TrustedShopsProps>()(TrustedShops);
const BlogHydration = withHydrationOnDemand<BlogMarketingMobileProps>()(BlogMarketing);
const BannerButtonWidthHydration = withHydrationOnDemand<BannerButtonWidthProps>()(BannerButtonWidth);
const LastNewsWidthHydration = withHydrationOnDemand<LastNewsProps>()(LastNews);
const MultisearcherWidthHydration = withHydrationOnDemand<MultisearcherProps>()(Multisearcher);
const CoastsHydration = withHydrationOnDemand<MapCoastsMobileProps>()(MobileMapCoasts);

export const MainHomeMobile: FC<MainHomeMobileProps> = ({ props }) => {
	const {
		mListCardSimpleHover,
		mCardImageCarousel,
		mCardMultiproductCarousel,
		mFeatures,
		mSectionImageLink,
		mComarketing,
		mCollapseLinksProducts,
		tabsProductCard,
		mTrustedShops,
		mBlog,
		mBannerButtonWidth,
		mLastNews,
		mTabsSearchers,
		mCoasts,
	} = parseRootProps<ComponentMobileMap>(props, {
		'm-list-card-simple': 'mListCardSimpleHover',
		'm-card-image-carousel': 'mCardImageCarousel',
		'm-card-multiproduct-carousel': 'mCardMultiproductCarousel',
		'm-features': 'mFeatures',
		'm-section-image-link': 'mSectionImageLink',
		'm-comarketing': 'mComarketing',
		'm-collapse-links-products': 'mCollapseLinksProducts',
		'm-tabs-campaign': 'tabsProductCard',
		'm-trustedshops': 'mTrustedShops',
		'm-blog': 'mBlog',
		'm-banner-button-width': 'mBannerButtonWidth',
		'm-last-news': 'mLastNews',
		'm-tabs-searchers': 'mTabsSearchers',
		'm-coasts': 'mCoasts',
	});
	return (
		<S.Main data-testid='MainHome'>
			{mTabsSearchers?.props && <MultisearcherWidthHydration {...mTabsSearchers} />}
			{tabsProductCard?.props && <TabsProductCardHydration {...tabsProductCard} />}
			{mListCardSimpleHover?.props && <ListCardSimpleHoverHydration {...mListCardSimpleHover} />}
			{mCardImageCarousel?.props && <MasonryCarouselHydration {...mCardImageCarousel} />}
			{mCardMultiproductCarousel?.props && <ProductsBannerHydration {...mCardMultiproductCarousel} />}
			{mSectionImageLink?.props && <SectionImageLinkHydration {...mSectionImageLink} />}
			{mFeatures?.props && <FeaturesHydration {...mFeatures} />}
			{mCollapseLinksProducts && <CollapseLinksProductsHydration {...mCollapseLinksProducts} />}
			{mComarketing?.props && <BannersHydration {...mComarketing} />}
			{mTrustedShops?.props && <TrustedShopsHydration {...mTrustedShops} />}
			{mBlog?.props && <BlogHydration {...mBlog} />}
			{mBannerButtonWidth?.props && <BannerButtonWidthHydration {...mBannerButtonWidth} />}
			{mLastNews?.props && <LastNewsWidthHydration {...mLastNews} />}
			{mCoasts?.props && <CoastsHydration {...mCoasts} />}
		</S.Main>
	);
};

export default MainHomeMobile;
