import InnerMarkdownHTML from '@babylon/ui-kit-base/components/text/inner-markdown-HTML';
import {
	AccordionContainer,
	AccordionContent,
	AccordionHeader,
} from '@babylon/ui-kit-structures/components/others/accordion/styled';
import { FlexMixin, FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import styled from 'styled-components';

export const Price = styled.span`
	margin-left: auto;
	color: ${({ theme }) => theme.colors.secondary.base};
	white-space: nowrap;
`;

export const PriceFrom = styled.span`
	${FontMixin({ size: 'small', weight: 'light', height: 'large' })}
`;

export const PriceContent = styled.span`
	${FontMixin({ size: 'base', weight: 'medium' })}
`;

export const Subtitle = styled.a`
	${FlexMixin({})}
	${FontMixin({ size: 'medium', weight: 'light', height: 'large' })}
	color: ${({ theme }) => theme.colors.grays.dark};
`;

export const AccordionWrapper = styled.div`
	padding: 0 16px;
	color: ${({ theme }) => theme.colors.grays.darker};
	margin-right: 40px;
	width: calc(33.3% - 20px);
	@media (max-width: 68.75em) {
		width: calc(42% - 94px);
	}
	@media (max-width: 768px) {
		width: calc(42% - 75px);
	}

	.title {
		${FontMixin({ size: 'base', weight: 'medium', height: 'large' })}
	}

	${AccordionContainer} {
		padding-bottom: 8px;
		padding-top: 12px;
	}

	${AccordionContent} {
		padding-top: 0px;
		overflow: hidden;
		transition: max-height 0.3s linear;

		&.is-hide {
			transform: unset;
			display: unset;
		}
	}

	${AccordionContainer}:last-child {
		border: none;
	}

	${AccordionHeader} {
		${FontMixin({ size: 'base', weight: 'regular', height: 'large' })}
		color: ${({ theme }) => theme.colors.grays.darker};
		padding-right: 0px;
		.header {
			width: 100%;
		}

		i {
			color: ${({ theme }) => theme.colors.grays.dark};
			padding-bottom: 30px;
			position: absolute;
			margin-left: 26.5%;
			@media (max-width: 68.75em) {
				margin-left: 24.5%;
			}
			@media (max-width: 768px) {
				margin-left: 21%;
			}
		}
	}
`;
export const Content = styled(InnerMarkdownHTML)`
	text-overflow: ellipsis;
	max-width: 500px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;
