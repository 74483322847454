import Title from '@babylon/ui-kit-base/components/text/title';
import type { SliderProps } from '@babylon/ui-kit-structures/components/others/slider/types';
import { Container } from '@babylon/ui-kit-styles/common/mixins/container.styled';
import type { FC } from 'react';
import { Children } from 'react';
import { useTheme } from 'styled-components';

import CardSimpleHover from './card-simple-hover';
import * as S from './styled';
import type { ListCardSimpleHoverDesktopProps } from './types';

export const ListCardSimpleHoverDesktop: FC<ListCardSimpleHoverDesktopProps> = ({ props, ...rest }) => {
	const { title = '', subtitle, items = [], descriptions, list } = props;
	const theme = useTheme();

	const slider: SliderProps = {
		breakpoints: {
			[theme.breakpoints.xxl]: { perPage: 4 },
			[theme.breakpoints.xl]: { perPage: 3 },
			[theme.breakpoints.lg]: { perPage: 2 },
		},
		perPage: 5,
		arrows: false,
		pagination: false,
		gap: 20,
	};

	return (
		<Container data-testid='ListCardSimpleHoverDesktop' {...rest}>
			<Title title={title} subTitle={subtitle} />
			{descriptions && Children.toArray(descriptions.map(desc => <S.Descriptions tag='p' content={desc} />))}
			{list && (
				<S.ListDescriptions data-testid='ListDescriptions'>
					{Children.toArray(list.map(item => <S.ItemDescriptions tag='li' content={item} />))}
				</S.ListDescriptions>
			)}
			<S.ListCardWrapper>
				<S.ListCardSlider {...slider}>{Children.toArray(items.map(CardSimpleHover))}</S.ListCardSlider>
			</S.ListCardWrapper>
		</Container>
	);
};

export * from './types';
export default ListCardSimpleHoverDesktop;
