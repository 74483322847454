import { Button } from '@babylon/ui-kit-base/components/buttons/button';
import InnerMarkdownHTML from '@babylon/ui-kit-base/components/text/inner-markdown-HTML';
import type { FC } from 'react';
import { Children } from 'react';

import useResizeImage from '@/hooks/useResizeImage';

import type { SectionImageLinkProps } from '../../types';
import * as S from './styled';

export const SectionImageLinkMobile: FC<SectionImageLinkProps> = ({ props }) => {
	const { image, title, description, links = [] } = props;
	const backgroundUri = useResizeImage(image);
	return (
		<S.InformationListImageMobile data-testid='SectionImageLinkMobile' imageUrl={backgroundUri}>
			<S.ContainerMobile>
				<S.TitleMobile>{title}</S.TitleMobile>
				<S.DescriptionMobile>{description && <InnerMarkdownHTML content={description} />}</S.DescriptionMobile>
				{Children.toArray(
					links.map(link => (
						<S.LinkMobile href={link.href} title={link.title}>
							<Button type='submit' variant='secondary' size='large'>
								{link.content}
							</Button>
						</S.LinkMobile>
					))
				)}
			</S.ContainerMobile>
		</S.InformationListImageMobile>
	);
};

export default SectionImageLinkMobile;
