import type { ContextBabylonProps } from '@babylon/ui-kit-context/types';
import BaseTemplateLogitravel from '@babylon/ui-kit-layout/components/logitravel/base-template';
import type { FC } from 'react';

import type { MainHomeDesktopProps, MainHomeMobileProps } from '@/pages/home';
import MainHome from '@/pages/home';
import { renderPages } from '@/utils/render';

import { transformProps } from './utils';

export interface AppProps {
	root: MainHomeDesktopProps | MainHomeMobileProps;
	context: ContextBabylonProps;
}

export const App: FC<AppProps> = props => {
	const { templateProps, mainHomeProps } = transformProps(props);

	return (
		<BaseTemplateLogitravel {...templateProps}>
			<MainHome {...mainHomeProps} />
		</BaseTemplateLogitravel>
	);
};

export const render = renderPages(App);
