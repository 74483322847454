import { Button } from '@babylon/ui-kit-base/components/buttons/button';
import Image from '@babylon/ui-kit-base/components/multimedia/image';
import type { ResizeProps } from '@babylon/ui-kit-base/components/multimedia/image/types';
import InnerMarkdownHTML from '@babylon/ui-kit-base/components/text/inner-markdown-HTML';
import { nullsToUndefined } from '@babylon/ui-kit-helpers/null';
import type { FC } from 'react';

import useResizeImage from '@/hooks/useResizeImage';

import type { BannerButtonWidthProps } from '../../types';
import * as S from './styled';

export const BannerButtonWidthMobile: FC<BannerButtonWidthProps> = ({ props }) => {
	const { config = {}, link, backgroundImage, logo } = props;
	const backgroundUri = useResizeImage(backgroundImage);

	const logoResize: ResizeProps = {
		width: 537,
		height: 209,
		operation: 'resize',
		quality: 95,
	};

	return (
		<S.BannerButtonWidthWrapperMobile data-testid='BannerButtonWidthMobile' backgroundImage={backgroundUri}>
			<S.BannerButtonWidthLinkMobile {...nullsToUndefined(link)}>
				<S.BannerButtonWidthLogoMobile>
					{logo && <Image resize={logoResize} {...logo} />}
				</S.BannerButtonWidthLogoMobile>
				<S.BannerButtonWidthInfoMobile>
					{config.literals?.description && (
						<InnerMarkdownHTML tag='p' content={config.literals?.description} />
					)}
					{link && (
						<Button variant='primary' {...nullsToUndefined(link)} height='42px'>
							{link.content}
						</Button>
					)}
				</S.BannerButtonWidthInfoMobile>
			</S.BannerButtonWidthLinkMobile>
		</S.BannerButtonWidthWrapperMobile>
	);
};

export default BannerButtonWidthMobile;
