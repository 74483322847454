import InnerMarkdownHTML from '@babylon/ui-kit-base/components/text/inner-markdown-HTML';
import { nullsToUndefined } from '@babylon/ui-kit-helpers/null';
import type { FC } from 'react';

import useResizeImage from '@/hooks/useResizeImage';

import * as S from './styled';
import type { CardSimpleHoverProps } from './types';

const CardSimpleHover: FC<CardSimpleHoverProps> = ({ title, description, image, link }) => {
	const backgroundUri = useResizeImage(image, { width: 375, height: 400, quality: 80 });

	return (
		<S.CardSimpleHoverWrapper data-testid='CardSimpleHover' $backgroundImage={backgroundUri}>
			<S.CardSimpleHoverDescription>
				<InnerMarkdownHTML className='card-simple-hover--title' tag='h3' content={title} />
				<InnerMarkdownHTML className='card-simple-hover--description' tag='p' content={description} />
				{link.content && <span className='card-simple-hover--btn'>{link.content}</span>}
			</S.CardSimpleHoverDescription>
			<a className='card-simple-hover--link' {...nullsToUndefined(link)} />
		</S.CardSimpleHoverWrapper>
	);
};

export default CardSimpleHover;
