import { FlexMixin, FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import styled from 'styled-components';

export const InformationListImageDesktop = styled.div<{ imageUrl: string }>`
	background-image: url(${props => props.imageUrl});
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
`;

export const ContainerDesktop = styled.div`
	${FlexMixin({ direction: 'column', justify: 'center', align: 'center' })}
	color: ${({ theme }) => theme.colors.white.base};
	margin: 0 auto 40px;
	max-width: 1500px;
	padding: 36px 0 40px;
	text-align: center;
	width: 100%;
`;

export const Title = styled.p`
	${FontMixin({ size: 'largest', height: 'larger', weight: 'regular' })}
	font-family: ${({ theme }) => theme.font.family.secondary};
	margin-bottom: 16px;
	width: 64%;
`;

export const Description = styled.p`
	${FontMixin({ weight: 'light' })}
	line-height: 22px;
	margin-bottom: 22px;
	max-width: none;
	width: 60%;
`;

export const Link = styled.a`
	${FontMixin({ weight: 'light' })}
	line-height: 22px;
	margin-bottom: 22px;
	max-width: none;
	width: 62%;
`;
