import { FlexMixin, FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import { BorderRadius } from '@babylon/ui-kit-styles/common/mixins/logical.styled';
import styled from 'styled-components';

export const CardSimpleHoverDescription = styled.div`
	${FlexMixin({ direction: 'column', justify: 'center', align: 'center' })}
	height: 100%;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: ${({ theme }) => theme.zIndex.lower};

	& > .card-simple-hover--title {
		color: ${({ theme }) => theme.colors.white.base};
		margin: 0 30px 24px;
		text-align: center;
		${FontMixin({ size: 'large', weight: 'regular', height: 'large' })}
	}

	& > .card-simple-hover--btn {
		${BorderRadius({ all: '4px' })}
		border: 1px solid ${({ theme }) => theme.colors.white.base};
		color: ${({ theme }) => theme.colors.white.base};
		cursor: pointer;
		display: inline-block;
		margin: 0 20px;
		outline: none;
		padding: 10px 12px;
		text-align: center;
		text-transform: uppercase;
		transition: 0.4s;
		vertical-align: middle;
		white-space: break-spaces;
		${FontMixin({ size: 'medium', weight: 'medium', height: 'medium' })}
	}

	& > .card-simple-hover--link {
		height: 100%;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: ${({ theme }) => theme.zIndex.low};
	}
`;

export const CardSimpleHoverWrapper = styled.div`
	display: inline-block;
	position: relative;
	width: 100%;

	& img {
		height: auto;
		width: 100%;
	}

	&:before {
		background-color: ${({ theme }) => theme.colors.black.high};
		content: '';
		height: 100%;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: ${({ theme }) => theme.zIndex.lowest};
	}
`;
