import InnerMarkdownHTML from '@babylon/ui-kit-base/components/text/inner-markdown-HTML';
import Slider from '@babylon/ui-kit-structures/components/others/slider';
import { FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import styled from 'styled-components';

export const ListCardWrapper = styled.div`
	min-height: 330px;
	padding: 0 4px;
`;

export const ListCardSlider = styled(Slider)`
	.splide__arrows .splide__arrow {
		${FontMixin({ size: 'largest', height: 'base' })}

		&:hover {
			color: ${({ theme }) => theme.colors.primary.base};
		}
	}

	.carousel-rxjs__slide {
		min-height: 350px;
		min-width: 268px;
		width: 268px;

		&:not(:last-of-type) {
			margin-right: 25px;
		}

		${({ theme }) => theme.media.down('md')} {
			min-width: 343px;
			width: 343px;
		}

		${({ theme }) => theme.media.between('md', 'xl')} {
			min-width: 306px;
			width: 306px;
		}
	}
`;

export const Descriptions = styled(InnerMarkdownHTML)`
	${FontMixin({ size: 'base', weight: 'light', height: 'base' })}
	color: ${({ theme }) => theme.colors.grays.darker};
	margin-left: 30px;
	text-align: center;
`;

export const ListDescriptions = styled.ul`
	list-style: initial;
	margin: 0 0 20px 36px;
`;

export const ItemDescriptions = styled(InnerMarkdownHTML)`
	${FontMixin({ size: 'base', weight: 'light', height: 'base' })}
	color: ${({ theme }) => theme.colors.grays.darker};
	margin-left: 30px;
	margin-top: 4px;
	text-align: left;
`;
