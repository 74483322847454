import { FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import { BorderRadius } from '@babylon/ui-kit-styles/common/mixins/logical.styled';
import styled from 'styled-components';

export const CardSimpleHoverDescription = styled.div`
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 100%;
	transition: all 0.3s linear;

	& > .card-simple-hover--title {
		${FontMixin({ size: 'largest', weight: 'light', height: 'largest' })}
		color: ${({ theme }) => theme.colors.white.base};
		font-size: 28px;
		margin-bottom: 16px;
		position: absolute;
		left: 16px;
		right: 16px;
		bottom: 100%;
	}

	& > .card-simple-hover--description {
		${FontMixin({ size: 'medium', weight: 'light', height: 'base' })}
		color: ${({ theme }) => theme.colors.white.base};
		display: none;
	}

	& > .card-simple-hover--btn {
		${FontMixin({ size: 'medium', weight: 'medium', height: 'base' })}
		color: ${({ theme }) => theme.colors.white.base};
		display: none;
	}
`;

export const CardSimpleHoverWrapper = styled.div<{ $backgroundImage: string }>`
	${BorderRadius({ all: '4px' })}
	background-image: url(${({ $backgroundImage }) => $backgroundImage});
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 350px;
	overflow: hidden;
	width: 100%;

	&:hover {
		${BorderRadius({ all: '4px' })}
		background-repeat: no-repeat;
		background-size: cover;
		box-shadow:
			0 1px 3px rgb(0 0 0 / 12%),
			0 1px 2px rgb(0 0 0 / 24%);
		overflow: hidden;
		position: relative;
		transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 0.1);
	}

	&:hover ${CardSimpleHoverDescription} {
		background-color: ${({ theme }) => theme.colors.primary.base};
		padding: 20px 16px;
		top: 0;

		& > .card-simple-hover--title {
			${FontMixin({ size: 'larger', weight: 'regular', height: 'largest' })}
			position: initial;
		}

		& > .card-simple-hover--description {
			display: block;
		}

		& > .card-simple-hover--btn {
			${BorderRadius({ all: '4px' })}
			border: 1px solid ${({ theme }) => theme.colors.white.base};
			bottom: 20px;
			cursor: pointer;
			display: inline-block;
			outline: none;
			overflow: hidden;
			padding: 8px 16px;
			position: absolute;
			right: 16px;
			text-align: center;
			text-transform: uppercase;
			transition: 0.4s;
			vertical-align: middle;
			white-space: nowrap;
		}
	}

	&:before {
		${BorderRadius({ all: '4px' })}
		background-color: rgba(0, 0, 0, 0.24);
		content: '';
		height: 100%;
		overflow: hidden;
		position: absolute;
		width: 100%;
	}

	& > .card-simple-hover--link {
		height: 100%;
		position: absolute;
		top: 0;
		width: 100%;
	}
`;
