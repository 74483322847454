import Icon from '@babylon/ui-kit-base/components/icons/icon';
import type { FC } from 'react';

import * as S from './styled';
import type { NewsletterSubscriptionStatusProps } from './types';

export const NewsletterSubscriptionStatus: FC<NewsletterSubscriptionStatusProps> = ({
	isLoading,
	icon = 'cancel',
	content,
	description,
	onClose,
}) => (
	<S.NewsletterSubscriptionStatusContent data-testid='NewsletterSubscriptionStatus' visible isLoading={isLoading}>
		<S.NewsletterSubscriptionStatusContainer>
			{isLoading ? (
				<div className='loading'>
					<span className='spinner' />
					<span className='text'>{content}</span>
				</div>
			) : (
				<>
					<Icon className={`nico-${icon}`} onClick={onClose} />
					<p className='title'>{content}</p>
					<p className='description'>{description}</p>
				</>
			)}
		</S.NewsletterSubscriptionStatusContainer>
	</S.NewsletterSubscriptionStatusContent>
);

export * from './types';
export default NewsletterSubscriptionStatus;
