import { TitleWrapper } from '@babylon/ui-kit-base/components/text/title/views/desktop/styled';
import DataProtection from '@babylon/ui-kit-cards/components/others/data-protection';
import { FormCheckbox } from '@babylon/ui-kit-forms/components/inputs/checkbox';
import { FlexMixin, FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import { BorderRadius } from '@babylon/ui-kit-styles/common/mixins/logical.styled';
import { rgba } from 'polished';
import styled from 'styled-components';

export const NewsletterSubscription = styled.div`
	background-color: ${({ theme }) => theme.colors.primary.light};
	color: ${({ theme }) => theme.colors.white.base};
	height: 100%;
	margin: 0 auto;
	max-width: 1500px;
	padding: 40px 20px;
	position: relative;
	width: 100%;
`;

export const NewsletterSubscriptionContent = styled.div<{ visible?: boolean; isLoading?: boolean }>`
	${FlexMixin({ direction: 'row', align: 'center', justify: 'space-between' })}
	background-color: ${({ theme, isLoading }) =>
		isLoading ? rgba(theme.colors.primary.light!, 0.85) : theme.colors.primary.light};
	visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};

	${TitleWrapper} span {
		color: white;
		padding: 0px;
	}

	${TitleWrapper} h2 {
		margin-bottom: 12px;
		padding: 0px;
	}

	${({ theme }) => theme.media.down('lg')} {
		${FlexMixin({ direction: 'column', align: 'center' })}
	}

	& > ${TitleWrapper} {
		padding-right: 118px;
		text-align: left;
		width: 42%;

		${({ theme }) => theme.media.down('lg')} {
			text-align: center;
			width: 100%;
		}
	}

	& > form {
		width: 58%;

		${({ theme }) => theme.media.down('lg')} {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			width: 100%;
		}
	}
`;

export const NewsletterSubscriptionContentForm = styled.div`
	${FlexMixin({ justify: 'space-between' })}
	flex-wrap: wrap;

	& > button {
		max-height: 44px;
		padding: 8px 16px;
		width: 146px;
	}
`;

export const ContentFormEmail = styled.div`
	background-color: ${({ theme }) => theme.colors.white.base};
	${BorderRadius({ all: '4px' })}
	width: calc(50% - 85px);
	height: 40px;

	& input {
		height: 40px;
		min-height: 40px;
		width: 100%;
	}
`;

export const ContentFormOrigin = styled.div`
	width: calc(50% - 85px);

	& .react-select__control {
		height: 42px;
		min-height: 42px;
	}

	& input {
		height: 40px;
		min-height: 40px;
	}
`;

export const ContentFormConditions = styled(FormCheckbox)`
	${FlexMixin({ align: 'center' })}
	margin: 8px 0;
	position: relative;

	& label > span {
		${FontMixin({ size: 'medium', weight: 'regular', height: 'medium' })}
		color: ${({ theme }) => theme.colors.white.base};
		margin-left: 8px;
		margin: 12px 0;
		width: 100%;
	}
`;

export const NewsletterSubscriptionInfoDataProtection = styled(DataProtection)`
	& .content-info-data-protection {
		${FontMixin({ size: 'medium', weight: 'light', height: 'base' })}
		color: ${({ theme }) => theme.colors.white.base};
	}

	& .link-info-data-protection {
		${FontMixin({ size: 'medium', weight: 'light', height: 'base' })}
		color: ${({ theme }) => theme.colors.white.base};
		cursor: pointer;
		margin: 0px 3px;
		text-decoration: underline;
	}

	& .data-protection-tooltip {
		text-align: left;
	}

	& .title-data-protection {
		${FontMixin({ size: 'medium', weight: 'medium' })}
		color: ${({ theme }) => theme.colors.grays.darker};
		margin-bottom: 4px;
	}

	& .content-data-protection {
		color: ${({ theme }) => theme.colors.grays.darker};
		display: inline;
	}

	& .link-data-protection {
		color: ${({ theme }) => theme.colors.primary.base};
		cursor: pointer;
		display: inline;
		text-decoration: underline;
	}
`;
