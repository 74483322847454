import { Button } from '@babylon/ui-kit-base/components/buttons/button';
import InnerMarkdownHTML from '@babylon/ui-kit-base/components/text/inner-markdown-HTML';
import type { FC } from 'react';
import { Children } from 'react';

import useResizeImage from '@/hooks/useResizeImage';

import type { SectionImageLinkProps } from '../../types';
import * as S from './styled';

export const SectionImageLinkDesktop: FC<SectionImageLinkProps> = ({ props }) => {
	const { image, title, description, links = [] } = props;
	const backgroundUri = useResizeImage(image);

	return (
		<S.InformationListImageDesktop data-testid='SectionImageLinkDesktop' imageUrl={backgroundUri}>
			<S.ContainerDesktop>
				<S.Title>{title}</S.Title>
				<S.Description>{description && <InnerMarkdownHTML content={description} />}</S.Description>
				{Children.toArray(
					links.map(link => (
						<S.Link href={link.href} title={link.title}>
							<Button type='submit' variant='secondary' size='large'>
								{link.content}
							</Button>
						</S.Link>
					))
				)}
			</S.ContainerDesktop>
		</S.InformationListImageDesktop>
	);
};

export default SectionImageLinkDesktop;
