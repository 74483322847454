import Title from '@babylon/ui-kit-base/components/text/title';
import { Container } from '@babylon/ui-kit-styles/common/mixins/container.styled';
import type { FC } from 'react';

import { CollapseLinksProductsList } from '../../render-links';
import * as S from './styled';
import type { CollapseLinksProductsDesktopProps } from './types';

export const CollapseLinksProductsDesktop: FC<CollapseLinksProductsDesktopProps> = ({
	title,
	listOne = [],
	listTwo = [],
	listThree = [],
	priceFrom,
}) => (
	<Container data-testid='CollapseLinksProductsDesktop'>
		{title && <Title title={title} />}
		{priceFrom && (
			<S.Wrapper>
				<CollapseLinksProductsList list={listOne} priceFrom={priceFrom} />
				<CollapseLinksProductsList list={listTwo} priceFrom={priceFrom} />
				<CollapseLinksProductsList list={listThree} priceFrom={priceFrom} />
			</S.Wrapper>
		)}
	</Container>
);

export * from './types';
export default CollapseLinksProductsDesktop;
