import contextBabylon from '@babylon/ui-kit-context';
import { formatCurrency } from '@babylon/ui-kit-helpers/currency';
import Accordion from '@babylon/ui-kit-structures/components/others/accordion';
import type { FC } from 'react';
import { Children, useContext } from 'react';

import * as S from './styled';
import type { CollapseLinksProductsProps, Items } from './types';

const ContentLink = ({ content, priceFrom }: { content?: Items; priceFrom?: string }) => {
	const { Currency } = useContext(contextBabylon);

	return (
		<S.Subtitle href={content?.href} target={content?.target}>
			{content?.content && <S.Content tag='p' content={content.content} />}
			{Currency && priceFrom && (
				<S.Price>
					<S.PriceFrom>{priceFrom} </S.PriceFrom>
					<S.PriceContent>{content?.price}</S.PriceContent>
					{Currency && <S.PriceFrom>{formatCurrency(Currency)}</S.PriceFrom>}
				</S.Price>
			)}
		</S.Subtitle>
	);
};

export const CollapseLinksProductsList: FC<CollapseLinksProductsProps> = ({
	list = [],
	priceFrom = '',
	iconOpen,
	iconClose,
}) => (
	<S.AccordionWrapper data-testid='CollapseLinksProductsList'>
		{Children.toArray(
			list.map(({ title, items = [] }) => {
				const [firstLink, ...restLink] = items;
				return (
					<Accordion
						iconOpen={iconOpen}
						iconClose={iconClose}
						header={
							<span className='title'>
								{title}
								<ContentLink content={firstLink} priceFrom={priceFrom} />
							</span>
						}
						content={
							<span className='title'>
								{Children.toArray(
									restLink.map(link => <ContentLink content={link} priceFrom={priceFrom} />)
								)}
							</span>
						}
					/>
				);
			})
		)}
	</S.AccordionWrapper>
);

export default CollapseLinksProductsList;
