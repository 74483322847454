import {
	AccordionContainer,
	AccordionContent,
	AccordionHeader,
} from '@babylon/ui-kit-structures/components/others/accordion/styled';
import { FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import styled from 'styled-components';

import { AccordionWrapper } from '../../render-links/styled';

export const WrapperMobile = styled.div`
	border-top: 1px solid ${({ theme }) => theme.colors.grays.light};
	border-bottom: 1px solid ${({ theme }) => theme.colors.grays.light};

	${AccordionWrapper} {
		padding: 0;
		margin-right: auto;
		width: auto;

		@media (max-width: 68.75em) {
			width: auto;
		}
		@media (max-width: 768px) {
			width: auto;
		}
		.title {
			${FontMixin({ size: 'base', weight: 'medium', height: 'large' })}
		}

		i {
			color: ${({ theme }) => theme.colors.grays.dark};
			right: 20px;
			padding-bottom: 30px;
			position: absolute;
		}

		${AccordionContainer} {
			padding-bottom: 8px;
			padding-top: 12px;
		}

		${AccordionContent} {
			padding-top: 0px;
		}

		${AccordionHeader} {
			${FontMixin({ size: 'base', weight: 'regular', height: 'large' })}
			color: ${({ theme }) => theme.colors.grays.darker};
			padding-right: 0px;
			.header {
				width: none;
			}
		}
	}
`;
