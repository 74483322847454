import { FlexMixin, FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import styled from 'styled-components';

export const InformationListImageMobile = styled.div<{ imageUrl: string }>`
	background-image: url(${props => props.imageUrl});
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
`;

export const ContainerMobile = styled.div`
	${FlexMixin({ direction: 'column', justify: 'center', align: 'center' })}
	color: ${({ theme }) => theme.colors.white.base};
	margin: 0 auto;
	padding: 32px 24px;
	text-align: center;
	width: 100%;
`;

export const TitleMobile = styled.p`
	${FontMixin({ size: 'larger', height: 'larger', weight: 'medium' })}
	font-family: ${({ theme }) => theme.font.family.secondary};
	margin-bottom: 12px;
`;

export const DescriptionMobile = styled.p`
	${FontMixin({ size: 'medium', weight: 'light', height: 'base' })}
	line-height: 22px;
	margin-bottom: 22px;
`;

export const LinkMobile = styled.a`
	${FontMixin({ size: 'medium', weight: 'medium', height: 'base' })}
	line-height: 22px;
	margin-bottom: 22px;
`;
