import Section from '@babylon/ui-kit-structures/components/others/section';
import { SectionContainer, SectionSearcher } from '@babylon/ui-kit-styles/common/mixins/section.styled';
import styled from 'styled-components';

import { SectionWithBorder } from '@/helpers/SectionWithBorder';

export const SectionHome = styled(SectionContainer)``;
export const MapCoast = styled(Section)`
	margin-bottom: 40px;
`;

export const MainContainer = styled.main`
	${SectionHome}, ${SectionSearcher}, ${SectionWithBorder} {
		margin-bottom: 40px;
	}

	${SectionSearcher} {
		margin-top: -48px;
	}
`;
